import { Fragment } from 'react'

import { ContentProvider } from './context'
import { Toolbar3 } from '../../../_metronic/layout/components/toolbar/custom/index'
import Formulario from './Form'

const Perfil = ( ) => {
    
    return (
        <Fragment>
            <ContentProvider>
                <Toolbar3
                    DefaultTitle={"PERFIL DE USUARIO"}
                />
                <Formulario/>
            </ContentProvider>
        </Fragment>
    )
}

export default Perfil
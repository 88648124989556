import {Route, Routes, Navigate} from 'react-router-dom'
import {lazy, Suspense} from 'react'
import {Spinner} from 'react-bootstrap'
import {useThemeMode} from '../../../../_metronic/partials'

const Persona = lazy(() => import('../../../views/usuario/persona/index'))
const User = lazy(() => import('../../../views/usuario/user/index'))
//const LazyOcupacion = lazy(() => import('../../../views/catalogos/ocupacion/index'))

const Usuario = () => {
  const {mode} = useThemeMode()
  return (
    <Suspense
      fallback={
        <>
          <div className={`ribbon-label txt-${mode ? 'dark' : 'white'} fw-bolder`}>
            Procesando
            <span className='ribbon-inner bg-dark'></span>
          </div>
          <div className='card-title w-100 text-center h1 text-dark'>
            <span className='me-10'>Por favor espere...</span>
            <Spinner as='span' animation='border' role='status' aria-hidden='true' />
          </div>
        </>
      }
    >
      <Routes>
        <Route path='persona' element={<Persona />} />
        <Route path='usuario' element={<User />} />
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Routes>
    </Suspense>
  )
}

export default Usuario

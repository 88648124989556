/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-tabs */
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { toDataURL } from '../global'
import { createContext, useEffect, useState } from 'react'
import { jwtDecode } from 'jwt-decode';
import Blowfish from 'javascript-blowfish'

export const UserContext = createContext()

export function getUserDataFromToken(userToken) {
  if (userToken) {
    const tokenParts = userToken.split('.')
    if (tokenParts.length === 3) {
      const payload = tokenParts[1]
      const decodedPayload = JSON.parse(atob(payload))
      return decodedPayload
    }
  }
  return null
}

// function extractRoles(userAuth) {
//   if (userAuth && userAuth['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']) {
//     return userAuth['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
//   }
//   return []
// }

export const UserProvider = ({ children }) => {
  const bf = new Blowfish(process.env.REACT_APP_CONFIG_KEY)
  const storage = localStorage.getItem('user')
  const publicPath = ['/auth/login', '/auth/forgot-password', '/auth/reset-password/', '/logout']
  const [userAuth, setUserAuth] = useState([])
  const [userRoles, setUseRoles] = useState([])
  const [base64] = useState([])
  const LogoPdf = toAbsoluteUrl(`/media/logos/logo.png`)

  useEffect(() => {
    if (storage) {
      // setUserAuth(userAuth?.token)
      const Decript = bf.decrypt(bf.base64Decode(storage))

      if (Decript) {
        const data = JSON.parse(Decript.replace(/\0/g, ''))
        setUserAuth(jwtDecode(data?.token))
        //   setUseRoles(extractRoles(getUserDataFromToken(data.token)))
        setUseRoles(data.roles)
        // console.log(extractRoles(getUserDataFromToken(data.token)))
      }
    } else {
      setUserAuth([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storage])

  const setLogos = async () => {
    await toDataURL(LogoPdf, (Base) => base64.push({ logoEPQ: Base }))
  }

  useEffect(() => {
    const url = window.location.pathname.split('/')
    if (!userAuth && url[1] !== 'auth') {
      localStorage.removeItem('user')
      window.location.replace('/auth')
    }
    setLogos()
  }, [userAuth])

  const value = { userAuth, userRoles, base64, publicPath, storage, getUserDataFromToken }

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>
}

// menuItems.ts

import Catalogos from './catalogos/index'
import Persona from './usuario/index'
import Otros from './otros'
import General from './general/index'
import Reportes from './reportes/index'
import ReportesSupervisor from './reportesSupervisor/index'
import SupervisorDashboard from './supervisorDashboard/index'

type MenuItem = {
  title: string
  path: string
  requiredRoles: string[]
  component?: React.FC<{ path: string; userRoles?: string[] }>;
}

const rolesConfig = JSON.parse(process.env.REACT_APP_ROLES || '{}');

export const menuItems: MenuItem[] = [
  {
    title: 'Noticias',
    path: 'dashboard',
    requiredRoles: [],
  },
  {
    title: 'Dashboard',
    path: 'supervisor-dashboard',
    requiredRoles: [rolesConfig.sup],
    component: SupervisorDashboard,
  },
  {
    title: 'Catalogos',
    path: 'catalogos',
    requiredRoles: [rolesConfig.ger, rolesConfig.inf, rolesConfig.cons],
    component: Catalogos,
  },
  {
    title: 'Gestión Campanario',
    path: 'gestion-campanario',
    requiredRoles: [rolesConfig.ger, rolesConfig.sup, rolesConfig.cons],
    component: General,
  },
  {
    title: 'Persona',
    path: 'usuario',
    requiredRoles: [rolesConfig.ger, rolesConfig.inf],
    component: Persona,
  },
  {
    title: 'Otros',
    path: 'otros',
    requiredRoles: [rolesConfig.ger, rolesConfig.sup, rolesConfig.cons],
    component: Otros,
  },
  {
    title: 'Reportes',
    path: 'reportes',
    requiredRoles: [rolesConfig.ger, rolesConfig.inf],
    component: Reportes,
  },
  {
    title: 'Reportes Supervisor',
    path: 'reportes-supervisor',
    requiredRoles: [rolesConfig.sup],
    component: ReportesSupervisor,
  }
]

import Blowfish from 'javascript-blowfish';

export function DecriptBlow() {
    const item = window.localStorage.getItem('user')

    let element = []

    if (item) {

        const bf = new Blowfish(process.env.REACT_APP_CONFIG_KEY);
        const Decript = bf.decrypt(bf.base64Decode(item));
        element = JSON.parse(Decript.replace(/\0/g, ''));
    }
    return element;
}

export function EncriptBlow(item) {
    const bf = new Blowfish(process.env.REACT_APP_CONFIG_KEY);
    const dataString = JSON.stringify(item);
    const encrypted = bf.encrypt(dataString);
    const encryptedMime = bf.base64Encode(encrypted);
    return encryptedMime
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    DecriptBlow,
    EncriptBlow
}
import {Route, Routes, Navigate} from 'react-router-dom'
import {lazy, Suspense, useContext} from 'react'
import {Spinner} from 'react-bootstrap'
import {useThemeMode} from '../../../../_metronic/partials'
import { UserContext } from '../../../util/context/userContext'

const LazyEstadoCivil = lazy(() => import('../../../views/catalogos/estadoCivil/index'))
const LazyOcupacion = lazy(() => import('../../../views/catalogos/ocupacion/index'))
const LazyTipoDetalle = lazy(() => import('../../../views/catalogos/tipoDetalle/index'))
const LazyTipoFamiliar = lazy(() => import('../../../views/catalogos/tipoFamiliar/index'))
const LazyUbicaciones = lazy(() => import('../../../views/catalogos/ubicaciones/index'))
const LazyTipoNoticia = lazy(() => import('../../../views/catalogos/tipoNoticia/index'))
const LazyProductos = lazy(() => import('../../../views/catalogos/productos/index'))

const Catalogos = () => {

  const { userRoles } = useContext(UserContext);
  const rolesConfig = JSON.parse(process.env.REACT_APP_ROLES || '{}');
  const {mode} = useThemeMode()

  const hasRole = (roleKey: any) => userRoles.includes(rolesConfig[roleKey]);

  const isGerOrInf = hasRole('ger') || hasRole('inf');
  const isCons = hasRole('cons');

  return (
    <Suspense
      fallback={
        <>
          <div className={`ribbon-label txt-${mode ? 'dark' : 'white'} fw-bolder`}>
            Procesando
            <span className='ribbon-inner bg-dark'></span>
          </div>
          <div className='card-title w-100 text-center h1 text-dark'>
            <span className='me-10'>Por favor espere...</span>
            <Spinner as='span' animation='border' role='status' aria-hidden='true' />
          </div>
        </>
      }
    >
      <Routes>
        {(isGerOrInf || isCons) && <Route path="ocupacion" element={<LazyOcupacion />} />}
        {isGerOrInf && (
          <>
            <Route path="estado-civil" element={<LazyEstadoCivil />} />
            <Route path="tipo-detalle" element={<LazyTipoDetalle />} />
            <Route path="tipo-familiar" element={<LazyTipoFamiliar />} />
            <Route path="ubicaciones" element={<LazyUbicaciones />} />
            <Route path="tipo-noticia" element={<LazyTipoNoticia />} />
            <Route path="productos" element={<LazyProductos />} />
          </>
        )}
        <Route path="*" element={<Navigate to="/error/404" />} />
      </Routes>
    </Suspense>
  )
}

export default Catalogos

import {FC} from 'react'
import {SidebarMenuItemWithSub} from '../../SidebarMenuItemWithSub'
import {SidebarMenuItem} from '../../SidebarMenuItem'
import {SidebarModel} from '../../../../../../../app/util/models/sidebar'

const Usuario: FC<SidebarModel> = ({path}) => {
  return (
    <SidebarMenuItemWithSub title='Gestión Usuario' icon={<i className='bi bi-person-lines-fill'></i>}>
      <SidebarMenuItem
        to={`/${path}/persona`}
        icon={<i className='bi bi-person' />}
        title='Persona'
      />
      <SidebarMenuItem
        to={`/${path}/usuario`}
        icon={<i className='bi bi-person-fill' />}
        title='Usuario'
      />
    </SidebarMenuItemWithSub>
  )
}

export default Usuario

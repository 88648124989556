import {Route, Routes, Navigate} from 'react-router-dom'
import {lazy, Suspense} from 'react'
import {Spinner} from 'react-bootstrap'
import {useThemeMode} from '../../../../_metronic/partials'

const Fors = lazy(() => import('../../../views/reportesSupervisor/fors/index'))
const Birthday = lazy(() => import('../../../views/reportesSupervisor/birthday'))
const Departamento = lazy(() => import('../../../views/reportesSupervisor/departamento'))
const Municipio = lazy(() => import('../../../views/reportesSupervisor/municipio'))

const ReportesSupervisor = () => {
  const {mode} = useThemeMode()
  return (
    <Suspense
      fallback={
        <>
          <div className={`ribbon-label txt-${mode ? 'dark' : 'white'} fw-bolder`}>
            Procesando
            <span className='ribbon-inner bg-dark'></span>
          </div>
          <div className='card-title w-100 text-center h1 text-dark'>
            <span className='me-10'>Por favor espere...</span>
            <Spinner as='span' animation='border' role='status' aria-hidden='true' />
          </div>
        </>
      }
    >
      <Routes>
        <Route path='fors' element={<Fors />} />
        <Route path='cumples' element={<Birthday />} />
        <Route path='departamento' element={<Departamento />} />
        <Route path='municipio' element={<Municipio />} />
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Routes>
    </Suspense>
  )
}

export default ReportesSupervisor

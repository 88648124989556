import React, { useState, useEffect } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import NewsCard from './NewsCard'
import * as Icon from 'react-feather'
import { GetRoute } from '../../services/public'
import { ResponseData } from '../../util/global'
import { Toolbar3 } from '../../../_metronic/layout/components/toolbar/custom'

interface NewsItem {
  id: string
  nombre: string
  descripcion: string
  nombreTipoNoticia: string
  tieneAdjunto: number
  adjunto?: string
  foto: string,
  fecha?: string
}

const NewsList: React.FC = () => {
  const [newsItems, setNewsItems] = useState<NewsItem[]>([])
  const [loading, setLoading] = useState<boolean>(false)

  const All = async () => {
    setLoading(true)
    const response = await GetRoute(`Noticia/all`)
    const data = ResponseData(response, "all")
    const filteredData = data?.filter((item: any) => item.estado === 1)
    setNewsItems(filteredData);
    setLoading(false)
  }

  useEffect(() => {
    All()
  }, [])

  if (newsItems.length === 0 && !loading) {
    return (
      <Container className="text-center py-5">
        <Icon.AlertCircle color="grey" size={40} />
        <div style={{ height: 20 }} />
        <p style={{ fontSize: 18, fontWeight: 400, color: 'grey' }}>
          No hay noticias disponibles.
        </p>
      </Container>
    )
  }

  return (
    <Container>
      <Toolbar3 DefaultTitle='Sección de Noticias' pageModal={null} />
      <Row className='mt-2'>
        {newsItems.map((noticia) => (
          <Col key={noticia.id} sm={12} md={6} lg={4} className="mb-3">
            <NewsCard noticia={noticia} />
          </Col>
        ))}
      </Row>
    </Container>
  )
}

export default NewsList
import {Suspense, useEffect} from 'react'
import {SWRConfig} from 'swr'
import {Outlet} from 'react-router-dom'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import {MasterInit} from '../_metronic/layout/MasterInit'
import {AuthInit} from './modules/auth'
import {UserProvider} from './util/context/userContext'
import { userService } from './services/public'

const App = () => {

  useEffect(() => {
    const currentVersion = localStorage.getItem('appVersionSS');
    const appVersionSS = process.env.REACT_APP_VERSION;
    const versionUpdatedSS = localStorage.getItem('versionUpdatedSS');
  
    // Si la versión no ha cambiado o ya se manejó la actualización, termina la ejecución del efecto.
    if (versionUpdatedSS === 'true' || currentVersion === appVersionSS) {
      localStorage.removeItem('versionUpdatedSS'); // Asegura que se limpie para futuras actualizaciones.
      return;
    }
  
    // Solo procede si es necesario actualizar la versión y recargar.
    if (currentVersion === null || currentVersion !== appVersionSS) {
      localStorage.setItem('appVersionSS', appVersionSS ? appVersionSS : '');
      localStorage.setItem('versionUpdatedSS', 'true'); // Marcar que la versión ha sido actualizada.
      userService.logout();
      window.location.reload();
    }
  }, []);

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <SWRConfig value={{provider: () => new Map()}}>
        <I18nProvider>
          <UserProvider>
            <LayoutProvider>
              <AuthInit>
                <Outlet />
                <MasterInit />
              </AuthInit>
            </LayoutProvider>
          </UserProvider>
        </I18nProvider>
      </SWRConfig>
    </Suspense>
  )
}

export {App}

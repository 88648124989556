import React, {Fragment, useContext} from 'react'
import {SidebarMenuItem} from '../SidebarMenuItem'

import {UserContext} from '../../../../../../app/util/context/userContext'
import {menuItems} from './menuItems'

const SidebarMenu: React.FC = () => {
  const {userRoles} = useContext(UserContext)
  const hasAnyRole = (requiredRoles: string[]) =>
    requiredRoles.some(role => userRoles.includes(role)); //Esto es para que pueda acceder si posee alguno de los roles

  return (
    <Fragment>
      <SidebarMenuItem to='/dashboard' icon={<i className='bi bi-newspaper'></i>} title='Noticias' />
      {menuItems.map((menuItem, index) => (
        <Fragment key={index}>
          {hasAnyRole(menuItem.requiredRoles) && menuItem.component ? (
            React.createElement(menuItem.component, {
              key: menuItem.path,
              path: menuItem.path,
              userRoles: userRoles,
            })
          ) : null}
        </Fragment>
      ))}
    </Fragment>
  )
}

export default SidebarMenu

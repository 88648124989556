import {FC} from 'react'
import {SidebarMenuItemWithSub} from '../../SidebarMenuItemWithSub'
import {SidebarMenuItem} from '../../SidebarMenuItem'
import {SidebarModel} from '../../../../../../../app/util/models/sidebar'

const General: FC<SidebarModel> = ({path}) => {
  return (
    <SidebarMenuItemWithSub title='Gestión Campanario' icon={<i className='bi bi-building' />}>
      <SidebarMenuItem
        to={`/${path}/citas`}
        icon={<i className='bi bi-calendar' />}
        title='Citas'
      />
      <SidebarMenuItem
        to={`/${path}/clientes`}
        icon={<i className='bi bi-person-fill' />}
        title='Clientes'
      />
    </SidebarMenuItemWithSub>
  )
}

export default General

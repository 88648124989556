import {FC} from 'react'
import {SidebarMenuItem} from '../../SidebarMenuItem'
import {SidebarModel} from '../../../../../../../app/util/models/sidebar'

const SupervisorDashboard: FC<SidebarModel> = ({path}) => {
  return (
      <SidebarMenuItem
        to={`/${path}/tablero`}
        icon={<i className='bi bi-bar-chart' />}
        title='Dashboard'
      />
  )
}

export default SupervisorDashboard

import {useContext, useEffect, useState} from 'react'
import {Row, Col, Form, Button, FormControl, InputGroup, Alert} from 'react-bootstrap'
import {useAuth} from '../../modules/auth'
import * as Icon from 'react-feather'
import {LoadingContext} from '../../util/loading/provider'
import {useForm} from 'react-hook-form'
import {PostRoute} from '../../services/private'
import {toast, Bounce} from 'react-toastify'
import {PerfilResetModel} from '../../util/models/perfil'
import ImageModal from '../../util/components/ImageModal'

const Formulario = () => {
  const {currentUser, logout} = useAuth()
  const {setShowLoad} = useContext(LoadingContext)
  const [showImageModal, setShowImageModal] = useState(false)
  const handleShowImageModal = () => setShowImageModal(true)
  const handleCloseImageModal = () => setShowImageModal(false)

  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm<PerfilResetModel>()
  const [Visualizar, setVisualizar] = useState<any>('password'),
    Mensaje = `
        La contraseña debe de tener ocho caracteres como mínimo, debe incluir
        letras mayúsculas,
        letras minúsculas,
        números y
        cualquiera de los siguientes caracteres: ( $, @, !, %, *, ?, &, _, + )`,

        onSubmit = async (data: any) => {
            if (data.repeatPassword !== data.newPassword) {
              toast.error('La contraseña nueva y la confirmación no coinciden', { position: 'bottom-right', transition: Bounce });
              return;
            }

            setShowLoad(true);
            const response = await PostRoute(`usuario/password/change`, {
              email: currentUser?.email,
              currentPassword: data.lastPassword,
              newPassword: data.newPassword,
              confirmNewPassword: data.repeatPassword,
            });
                    
            if (response.value !== "1") {              
              toast.error('Proceso no realizado. Asegúrese que está ingresando la contraseña actual correctamente.', { position: 'bottom-right', transition: Bounce });
              setShowLoad(false);
              return;
            }
        
            toast.success(response.message + ". Su sesión actual se cerrará.", { position: 'bottom-right', transition: Bounce });
            setTimeout(logout, 5000)
        
            setShowLoad(false);
        }
        

  useEffect(() => {
    const functionFetch = async () => {
      // All()s
      //AllRoles()
    }
    functionFetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div
      // ref={stepperRef}
      className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid mt-5'
      id='kt_create_account_stepper'
    >
      {/* begin::Aside*/}
      <div className='card d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px w-xxl-400px me-9'>
        {/* begin::Wrapper*/}
        <div className='card-body px-6 px-lg-10 px-xxl-15 py-20'>
          <div className='symbol symbol-circle d-flex justify-content-center my-10'>

          {
            currentUser &&
                <div
                  onClick={handleShowImageModal}
                  className='symbol-label w-200px h-200px'
                  style={{
                    backgroundImage: `url('${currentUser?.foto || ''}')`,
                    cursor: 'pointer'
                  }}
                >
                </div>
              }
            <ImageModal imageUrl={currentUser?.foto || ''} show={showImageModal} onHide={handleCloseImageModal} />
          </div>
          <Row>
            <Col lg={12}>
              <Form.Group className='mb-3'>
                <i className='bi bi-person-fill me-5 text-dark fs-3'></i>{' '}
                {currentUser?.username || ''}
              </Form.Group>
            </Col>
            <Col lg={12}>
              <Form.Group className='mb-3'>
                <i className='bi bi-person-circle me-5 text-dark fs-3'></i>{' '}
                {currentUser?.nombreCompleto || ''}
              </Form.Group>
            </Col>
            <Col lg={12}>
              <Form.Group className='mb-3'>
                <i className='bi bi-telephone-fill me-5 text-dark fs-3'></i>{' '}
                {currentUser?.telefono}
              </Form.Group>
            </Col>
            <Col lg={12}>
              <Form.Group className='mb-3'>
                <i className='bi bi-envelope me-5 text-dark fs-3'></i> {currentUser?.email}
              </Form.Group>
            </Col>
            {/* <Col lg={12}>
              <Form.Group className='mb-3'>
                <i className='bi bi-person-badge-fill me-5 text-dark fs-3'></i>{' '}
                {oneData && oneData.dpi}
              </Form.Group>
            </Col>
            <Col lg={12}>
              <Form.Group className='mb-3'>
                <i className='bi bi-person-badge me-5 text-dark fs-3'></i> {oneData && oneData.nit}
              </Form.Group>
            </Col>
            <Col lg={12}>
              <Form.Group className='mb-3'>
                <i className='fa fa-venus-mars me-5 text-dark fs-3'></i>
                {oneData && oneData.genero}
              </Form.Group>
            </Col> */}
          </Row>
        </div>
        {/* end::Wrapper*/}
      </div>
      {/* begin::Aside*/}

      <div className='d-flex flex-row-fluid flex-center bg-body rounded'>
        <Form
          onSubmit={handleSubmit(onSubmit)}
          className='py-20 w-100 w-xl-700px px-9'
          id='kt_create_account_form'
        >
          <Row>
            <Col>
              <div className='fw-bolder py-2 h3'>{'Actualizar Contraseña'}</div>
              <Alert variant='warning'>
                <small>{Mensaje}</small>
              </Alert>
            </Col>
          </Row>
          <Form.Group className='mb-3' controlId='lastPassword'>
            <Form.Label>{'Contraseña Anterior'}</Form.Label>
            <InputGroup className='mb-3'>
              <FormControl
                type={Visualizar}
                {...register('lastPassword', {
                  required: 'Este campo es requerido',
                  minLength: {
                    value: 8,
                    message: 'Mínimo 8 carácteres!',
                  },
                })}
                isInvalid={!!errors.lastPassword}
              />
              <Button
                onClick={
                  Visualizar === 'password'
                    ? () => setVisualizar('text')
                    : () => setVisualizar('password')
                }
                variant='secondary'
              >
                <Icon.Eye size={16} />
              </Button>
            </InputGroup>
            <p className='text-danger fw-danger'>
              {!!errors.lastPassword && errors.lastPassword.message}
            </p>
          </Form.Group>
          <Form.Group className='mb-3' controlId='newPassword'>
            <Form.Label>{'Nueva Contraseña'}</Form.Label>
            <Form.Control
              type={Visualizar}
              {...register('newPassword', {
                required: 'Este campo es requerido',
                pattern: {
                  value:
                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!_%*+?&])[A-Za-z\d$@$!_%*+?&]{8,15}[^'\s]/,
                  message: 'La contraseña no cumple con los requisitos',
                },
                minLength: {
                  value: 8,
                  message: 'Mínimo 8 carácteres!',
                },
              })}
              isInvalid={!!errors.newPassword}
            />
            <p className='text-danger fw-danger'>
              {!!errors.newPassword && errors.newPassword.message}
            </p>
          </Form.Group>
          <Form.Group className='mb-3' controlId='repeatPassword'>
            <Form.Label>{'Repetir Contraseña'}</Form.Label>
            <Form.Control
              type={Visualizar}
              {...register('repeatPassword', {
                required: 'Este campo es requerido',
                pattern: {
                  value:
                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!_%*+?&])[A-Za-z\d$@$!_%*+?&]{8,15}[^'\s]/,
                  message: 'La contraseña no cumple con los requisitos',
                },
                minLength: {
                  value: 8,
                  message: 'Mínimo 8 carácteres!',
                },
              })}
              isInvalid={!!errors.repeatPassword}
            />
            <p className='text-danger fw-danger'>
              {!!errors.repeatPassword && errors.repeatPassword.message}
            </p>
          </Form.Group>
          <div className='d-grid gap-2 col-6 mx-auto'>
            <Button variant='success' size='sm' type='submit'>
              <Icon.Save size={16} /> Actualizar Contraseña
            </Button>
          </div>
        </Form>
      </div>
    </div>
  )
}

export default Formulario

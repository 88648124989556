import {FC} from 'react'
import {SidebarMenuItemWithSub} from '../../SidebarMenuItemWithSub'
import {SidebarMenuItem} from '../../SidebarMenuItem'

interface CatalogosProps {
  path: string;
  userRoles?: string[];
}

const Otros: FC<CatalogosProps> = ({ path, userRoles }) => {
  
  const rolesConfig = JSON.parse(process.env.REACT_APP_ROLES || '{}');
  const isGer = userRoles?.includes(rolesConfig?.ger);
  const isInf = userRoles?.includes(rolesConfig?.inf);
  const isSup = userRoles?.includes(rolesConfig?.sup);
  const isCons = userRoles?.includes(rolesConfig?.cons);

  return (
    <SidebarMenuItemWithSub title='Otras Gestiones' icon={<i className='bi bi-three-dots' />}>
      
      {(isGer || isCons || isSup || isInf) && (
          <SidebarMenuItem
          to={`/${path}/Ubicacion`}
          icon={<i className='bi bi-plus' />}
          title='Ubicación'
        />
      )}

      {isGer && (
        <>
            <SidebarMenuItem
              to={`/${path}/gestion-noticias`}
              icon={<i className='bi bi-pencil-square' />}
              title='Noticias'
            />
            <SidebarMenuItem
              to={`/${path}/supervisor-consejero`}
              icon={<i className='bi bi-person-lines-fill' />}
              title='Supervisor Consejero'
            />
        </>
      )}
    </SidebarMenuItemWithSub>
  )
}

export default Otros

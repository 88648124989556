import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { ArrowLeft } from 'react-feather';

interface ImageModalProps {
  imageUrl: string;
  show: boolean; // Añadido para controlar la visibilidad desde fuera
  onHide: () => void; // Función para ocultar el modal
}

const ImageModal: React.FC<ImageModalProps> = ({ imageUrl, show, onHide }) => {
  return (
    <Modal show={show} onHide={onHide} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>Visualización de Imagen</Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex justify-content-center">
        <img src={imageUrl} alt="Visualización" style={{ maxWidth: '100%', maxHeight: '70vh' }} />
      </Modal.Body>
      <Modal.Footer className='d-flex justify-content-center'>
            <Button
              data-tooltip-id='exit'
              data-tooltip-content='Salir'
              variant='secondary'
              size='sm'
              className='btn-icon me-5'
              onClick={onHide}
            >
              <ArrowLeft size={18} />
            </Button>              
          </Modal.Footer>
    </Modal>
  );
};

export default ImageModal;
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import Dashboard from '../views/dashboard/index'
import Perfil from '../views/perfil/index'
import Usuario from './private/usuario'
import Otros from './private/otros/index'
import General from './private/general'
import Reportes from './private/reportes'
import ReportesSupervisor from './private/reportesSupervisor'
import SupervisorDashboard from './private/supervisorDashboard'
import {useContext} from 'react'
import {UserContext} from '../util/context/userContext'

import Catalogos from './private/catalogos'

const rolesConfig = JSON.parse(process.env.REACT_APP_ROLES || '{}');

//aca podes poner roles por los cuales puede acceder
const routeConfig = {
  dashboard: {path: 'dashboard', component: Dashboard, roles: []},
  catalogos: {path: 'catalogos/*', component: Catalogos, roles: [rolesConfig.ger, rolesConfig.inf, rolesConfig.cons]},
  usuario: {path: 'usuario/*', component: Usuario, roles: [rolesConfig.ger, rolesConfig.inf]},
  otros: {path: 'otros/*', component: Otros, roles: [rolesConfig.ger, rolesConfig.sup, rolesConfig.cons]},
  gestion: {path: 'gestion-campanario/*', component: General, roles: [rolesConfig.ger, rolesConfig.sup, rolesConfig.cons]},
  reportes: {path: 'reportes/*', component: Reportes, roles: [rolesConfig.ger, rolesConfig.inf]},
  reportesSupervisor: {path: 'reportes-supervisor/*', component: ReportesSupervisor, roles: [rolesConfig.sup]},
  supervisorDashboard: {path: 'supervisor-dashboard/*', component: SupervisorDashboard, roles: [rolesConfig.sup]},

  perfil: {path: 'perfil', component: Perfil, roles: []},
}

const PrivateRoutes = () => {
  const {userRoles} = useContext(UserContext)

  // Filtra las rutas basadas en el rol del usuario
  const filteredRoutes = Object.values(routeConfig).filter((route) => {
    if (route.roles.length === 0) {
      return true; // Si no se requieren roles específicos, permitir acceso
    }
    return route.roles.some(role => userRoles.includes(role)); // Verifica si el usuario tiene alguno de los roles permitidos
  });

  // Genera rutas
  const generatedRoutes = filteredRoutes.map((route) => (
    <Route key={route.path} path={route.path} element={<route.component />} />
  ));

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {generatedRoutes}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  );
}

export {PrivateRoutes}

import {Route, Routes, Navigate} from 'react-router-dom'
import {lazy, Suspense, useContext} from 'react'
import {Spinner} from 'react-bootstrap'
import {useThemeMode} from '../../../../_metronic/partials'
import { UserContext } from '../../../util/context/userContext'

const Noticias = lazy(() => import('../../../views/otros/noticia/index'))
const Ubicacion = lazy(() => import('../../../views/otros/ubicacion/index'))
const SupervisorConsejero = lazy(() => import('../../../views/otros/supervisorConsejero/index'))
//const LazyOcupacion = lazy(() => import('../../../views/catalogos/ocupacion/index'))

const Otros = () => {
  const {mode} = useThemeMode()
  const { userRoles } = useContext(UserContext)
  
  const rolesConfig = JSON.parse(process.env.REACT_APP_ROLES || '{}');

  const hasRole = (roleKey: any) => userRoles.includes(rolesConfig[roleKey]);

  const isGer = hasRole('ger');
  const isInf = hasRole('inf');
  const isSup = hasRole('sup');
  const isCons = hasRole('cons');

  return (
    <Suspense
      fallback={
        <>
          <div className={`ribbon-label txt-${mode ? 'dark' : 'white'} fw-bolder`}>
            Procesando
            <span className='ribbon-inner bg-dark'></span>
          </div>
          <div className='card-title w-100 text-center h1 text-dark'>
            <span className='me-10'>Por favor espere...</span>
            <Spinner as='span' animation='border' role='status' aria-hidden='true' />
          </div>
        </>
      }
    >
      <Routes>
        {(isGer || isCons || isInf || isSup) && <Route path='ubicacion' element={<Ubicacion />} />}
        {isGer && (
          <>
              <Route path='gestion-noticias' element={<Noticias />} />
              <Route path='supervisor-consejero' element={<SupervisorConsejero />} />
          </>
        )}
        <Route path="*" element={<Navigate to="/error/404" />} />
      </Routes>
    </Suspense>
  )
}

export default Otros

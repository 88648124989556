import { Bounce } from 'react-toastify'

export const OptionsToast = { position: 'bottom-right', transition: Bounce }

export const ResponseData = (data, type) => {
  let response = [];
  switch (type) {
    case "all":
      response = Array.isArray(data) > 0 ? data : [];
      break;
    case "one":
      response = data && data[0] ? data[0] : [];
      break;
    default:
      break;
  }
  return response;
};

export const obtenerBase64DeArchivo = async (archivo) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(archivo);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

export const obtenerExtension = (archivo) => {
  const partesNombre = archivo.name.split(".");
  return partesNombre[partesNombre.length - 1];
};

export function formatDate(date, lang) {
  const d = new Date(date)
  let fecha = null
  if (lang === 'en') {
    fecha = `${d.getFullYear()}-${`00${d.getMonth() + 1}`.slice(-2)}-${`00${d.getDate()}`.slice(
      -2
    )}`
  }
  if (lang === 'es') {
    fecha = `${`00${d.getDate()}`.slice(-2)}-${`00${d.getMonth() + 1}`.slice(
      -2
    )}-${d.getFullYear()}`
  }
  if (lang === 'es-en') {
    const SplitDate = date.split('-')
    fecha = `${SplitDate[2]}-${SplitDate[1]}-${SplitDate[0]}`
    fecha = new Date(fecha + "T00:00:00")
  }
  return fecha
}

export function formatDateString(date, lang) {
  const d = new Date(date);
  let fecha = '';

  if (lang === 'en') {
    fecha = `${d.getFullYear()}-${`0${d.getMonth() + 1}`.slice(-2)}-${`0${d.getDate()}`.slice(-2)}`;
  } else if (lang === 'es') {
    fecha = `${`0${d.getDate()}`.slice(-2)}-${`0${d.getMonth() + 1}`.slice(-2)}-${d.getFullYear()}`;
  } else if (lang === 'es-en') {
    // Este bloque pareciera querer revertir un formato 'es' a 'en', pero termina creando un objeto Date nuevamente.
    // Si deseas conservar este bloque, deberías asegurarte de retornar un string.
    const splitDate = date.split('-');
    fecha = `${splitDate[2]}-${splitDate[1]}-${splitDate[0]}`;
  }
  
  return fecha;
}

export function formatDateToString(date) {
  if (!date) return ''; // Asegura que la fecha no sea nula o indefinida

  const d = new Date(date);
  if (isNaN(d.getTime())) return ''; // Verifica si la fecha es inválida

  // Retorna la fecha en formato yyyy-MM-dd
  return `${d.getFullYear()}-${`0${d.getMonth() + 1}`.slice(-2)}-${`0${d.getDate()}`.slice(-2)}`;
}


export const stringToDate = (_date, _format, _delimiter) => {
  const formatLowerCase = _format.toLowerCase()
  const formatItems = formatLowerCase.split(_delimiter)
  const dateItems = _date.split(_delimiter)
  const monthIndex = formatItems.indexOf('mm')
  const dayIndex = formatItems.indexOf('dd')
  const yearIndex = formatItems.indexOf('yyyy')
  let month = parseInt(dateItems[monthIndex])
  month -= 1
  const formatedDate = new Date(dateItems[yearIndex], month, dateItems[dayIndex])
  return formatedDate
}

export const styleClass = (base) => ({
  ...base,
  border: 'solid 1px #fd397a !important',
  boxShadow: 'none',
  borderRadius: '5px',
})

export const customStyles = {
  control: styleClass,
}

export function dataURItoBlob(dataURI) {
  const byteString = atob(dataURI.split(',')[1])
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

  const ab = new ArrayBuffer(byteString.length)
  const ia = new Uint8Array(ab)

  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }
  const blob = new Blob([ab], { type: mimeString })

  return blob
}

export function blobToBase64(blob) {
  return new Promise((resolve, _) => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result)
    reader.readAsDataURL(blob)
  })
}

export function soloNumeros(e) {
  var regex = new RegExp('^[0-9.]+$')
  var key = String.fromCharCode(!e.charCode ? e.which : e.charCode)
  if (e.target.value.includes(key === '.' && key)) {
    e.preventDefault()
    return false
  }

  if (!regex.test(key)) {
    e.preventDefault()
    return false
  }
}

export const toDataURL = (url, callback) => {
  var xhr = new XMLHttpRequest()
  xhr.onload = function () {
    var reader = new FileReader()
    reader.onloadend = function () {
      callback(reader.result)
    }
    reader.readAsDataURL(xhr.response)
  }
  xhr.open('GET', url)
  xhr.responseType = 'blob'
  xhr.send()
}

export function formatearNumero(numero) {
  try {
    let numeroFormateado = parseFloat(numero)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, "$&,");
    return numeroFormateado;
  } catch (error) {
    return "Error: Ingresa un número válido.";
  }
}

export const getColorForTipoNoticia = (tipoNoticia) => {
  switch (tipoNoticia) {
    case 'Recreacion': return 'success'
    case 'Interna': return 'primary'
    case 'Informativa': return 'warning'
    case 'Urgente': return 'danger'
    default: return 'secondary'
  }
}

export function formatDateToMMYYYY(date) {
  if (!date) return ''; // Asegura que la fecha no sea nula o indefinida

  const d = new Date(date);
  if (isNaN(d.getTime())) return ''; // Verifica si la fecha es inválida

  // Retorna la fecha en formato MM-yyyy
  return `${`0${d.getMonth() + 1}`.slice(-2)}-${d.getFullYear()}`;
}

export function formatDateTimeISO(date) {
  if (!date) return '';

  const d = new Date(date);
  if (isNaN(d.getTime())) return '';
  return `${d.getFullYear()}-${`0${d.getMonth() + 1}`.slice(-2)}-${`0${d.getDate()}`.slice(-2)}T${`0${d.getHours()}`.slice(-2)}:${`0${d.getMinutes()}`.slice(-2)}:00`;
}

export function formatDateTimeLocal(date) {
  const d = new Date(date);
  if (isNaN(d.getTime())) return ''; // Verifica si la fecha es inválida

  // Retorna la fecha y hora en formato local yyyy-MM-dd HH:mm:ss
  return `${d.getFullYear()}-${`0${d.getMonth() + 1}`.slice(-2)}-${`0${d.getDate()}`.slice(-2)} ${`0${d.getHours()}`.slice(-2)}:${`0${d.getMinutes()}`.slice(-2)}:${`0${d.getSeconds()}`.slice(-2)}`;
}

export const verifyOneData = (oneData) => {
  return typeof oneData === 'object' && 
  oneData !== null &&
  Object.keys(oneData).length > 0
}  

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  OptionsToast,
  customStyles,
  dataURItoBlob,
  stringToDate,
  blobToBase64,
  soloNumeros,
  formatDate,
  toDataURL,
  formatearNumero,
  getColorForTipoNoticia,
  formatDateString,
  formatDateToString,
  formatDateToMMYYYY,
  formatDateTimeISO,
  formatDateTimeLocal,
  verifyOneData
}

import { FC, ReactNode } from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router';
import { checkIsActive, KTSVG, WithChildren } from '../../../../helpers';

type Props = {
  to: string;
  title: string;
  icon?: string | ReactNode; // Modificado para aceptar string o JSX
  fontIcon?: string;
  hasBullet?: boolean;
};

const SidebarMenuItem: FC<Props & WithChildren> = ({
  children,
  to,
  title,
  icon,
  fontIcon,
  hasBullet = false,
}) => {
  const { pathname } = useLocation();
  const isActive = checkIsActive(pathname, to);

  return (
    <div className="menu-item">
      <Link className={clsx('menu-link without-sub', { active: isActive })} to={to}>
        {hasBullet && (
          <span className="menu-bullet">
            <span className="bullet bullet-dot"></span>
          </span>
        )}
        {icon && (
          <span className="menu-icon">
            {typeof icon === 'string' ? (
              <KTSVG path={icon as string} className="svg-icon-2" />
            ) : (
              // Renderizar JSX directamente
              <>{icon}</>
            )}
          </span>
        )}
        {fontIcon && <i className={clsx('bi fs-3 me-4 ', fontIcon)}></i>}
        <span className="menu-title">{title}</span>
      </Link>
      {children}
    </div>
  );
};

export { SidebarMenuItem };

import React, { useState } from 'react'
import { Card, Modal, Button } from 'react-bootstrap'
import { Download, Eye } from 'react-feather'
import './NewsCard.css'
import AttachmentModal from './AttachmentModal'

interface NewsItem {
  id: string
  nombre: string
  descripcion: string
  nombreTipoNoticia: string
  tieneAdjunto: number
  adjunto?: string,
  foto: string,
  fecha?: string
}

interface NewsCardProps {
  noticia: NewsItem
}

const NewsCard: React.FC<NewsCardProps> = ({ noticia }) => {
  const [showModal, setShowModal] = useState(false)
  const [showAttachmentModal, setShowAttachmentModal] = useState(false)

  const getColorForTipoNoticia = (tipoNoticia: string): string => {
    switch (tipoNoticia) {
      case 'Recreacion': return 'success'
      case 'Interna': return 'primary'
      case 'Informativa': return 'warning'
      case 'Urgente': return 'danger'
      default: return 'secondary'
    }
  }

  return (
    <>
      <Card className="news-card">
        <Card.Body>
          <Card.Title className="news-card-title">{noticia.nombre}</Card.Title>
          <Card.Text className="news-card-description">{noticia.descripcion}</Card.Text>

          <div className="news-card-action">
            {/* <div className="news-card-date">
              {noticia.fecha}
              25/02/2024 14:38
            </div> */}
          <Button variant="link" className="icon-button d-lg-none" onClick={() => setShowModal(true)}>
              <Eye size={16} />
            </Button>            
            {
                noticia.tieneAdjunto === 1 && (
                    <>
                    <Button variant="link" onClick={() => setShowAttachmentModal(true)}>
                        <Download size={16} />
                    </Button>
                    <AttachmentModal 
                        attachmentUrl={noticia.foto} 
                        show={showAttachmentModal} 
                        onHide={() => setShowAttachmentModal(false)} 
                    />
                    </>
                )
            }
            <div className={`news-card-type ${getColorForTipoNoticia(noticia.nombreTipoNoticia)}`}>
              {noticia.nombreTipoNoticia}
            </div>
          </div>
        </Card.Body>
      </Card>

      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Descripción completa</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{noticia.descripcion}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default NewsCard
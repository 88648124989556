// import {AuthModel} from './_models'
import { DecriptBlow, EncriptBlow } from '../../../util/blowfish'

const AUTH_LOCAL_STORAGE_KEY = 'user'
const getAuth = (): any | undefined => {
  if (!localStorage) {
    return
  }

  const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)

  if (!lsValue) {
    return
  }

  try {
    let auth: any = DecriptBlow() as any

    if (auth.data) {
      auth = {
        ...auth,
        actualizar: auth.actualizar ? auth.actualizar : 2,
      }
    }

    if (auth) {
      // You can easily check auth_token expiration also
      return auth
    }
  } catch (error) {
    console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
  }
}

const setAuth = (auth: any) => {
  if (!localStorage) {
    return
  }

  try {
    // const lsValue = JSON.stringify(auth)
    // setear data
    localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, EncriptBlow(auth))
  } catch (error) {
    console.error('AUTH LOCAL STORAGE SAVE ERROR', error)
  }
}

const removeAuth = () => {
  if (!localStorage) {
    return
  }

  try {
    localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE REMOVE ERROR', error)
  }
}

export { getAuth, setAuth, removeAuth, AUTH_LOCAL_STORAGE_KEY }

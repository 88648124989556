import {FC} from 'react'
import {SidebarMenuItemWithSub} from '../../SidebarMenuItemWithSub'
import {SidebarMenuItem} from '../../SidebarMenuItem'
import {SidebarModel} from '../../../../../../../app/util/models/sidebar'

const ReportesSupervisor: FC<SidebarModel> = ({path}) => {
  return (
    <SidebarMenuItemWithSub title='Reportes Supervisor' icon={<i className='bi bi-graph-up' />}>
      <SidebarMenuItem
        to={`/${path}/fors`}
        icon={<i className='bi bi-people-fill' />}
        title='FORS'
      />
      <SidebarMenuItem
        to={`/${path}/cumples`}
        icon={<i className='bi bi-calendar-week' />}
        title='Cumpleaños'
      />
      <SidebarMenuItem
        to={`/${path}/departamento`}
        icon={<i className='bi bi-pin' />}
        title='Departamento'
      />
      <SidebarMenuItem
        to={`/${path}/municipio`}
        icon={<i className='bi bi-pin' />}
        title='Municipio'
      />
    </SidebarMenuItemWithSub>
  )
}

export default ReportesSupervisor

import { useThemeMode } from '../../../_metronic/partials/layout/theme-mode/ThemeModeProvider'
import { useContext } from 'react'
import { Modal, Spinner } from 'react-bootstrap'
import { LoadingContext } from './provider'

export const Loading = () => {
    const { mode } = useThemeMode()
    const { showLoad } = useContext(LoadingContext)
    return (
        <Modal show={showLoad} backdrop="static" centered keyboard={false} style={{ zIndex: '10000000000', "backgroundColor": "rgba(0,0,0,0.7)" }}>
            <Modal.Body className="ribbon ribbon-start ribbon-clip">
                <div className={`ribbon-label txt-${mode ? 'dark' : 'white' } fw-bolder`}>
                    Procesando
                    <span className="ribbon-inner bg-dark"></span>
                </div>
                <div className="card-title w-100 text-center h1 text-dark"><span className="me-10">Por favor espere...</span>
                    <Spinner
                        as="span"
                        animation="border"
                        role="status"
                        aria-hidden="true"
                    />
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default Loading
import {FC} from 'react'
import {SidebarMenuItemWithSub} from '../../SidebarMenuItemWithSub'
import {SidebarMenuItem} from '../../SidebarMenuItem'
import {SidebarModel} from '../../../../../../../app/util/models/sidebar'

const Reportes: FC<SidebarModel> = ({path}) => {
  return (
    <SidebarMenuItemWithSub title='Reportes' icon={<i className='bi bi-graph-up' />}>
      <SidebarMenuItem
        to={`/${path}/top-mas-clientes`}
        icon={<i className='bi bi-people-fill' />}
        title='Más Clientes'
      />
      <SidebarMenuItem
        to={`/${path}/fors`}
        icon={<i className='bi bi-people-fill' />}
        title='FORS'
      />
    </SidebarMenuItemWithSub>
  )
}

export default Reportes

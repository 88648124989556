import { createContext, useState, /*useContext,*/ useEffect, FC } from 'react'
//import { PostRoute } from '../../services/private'
//import { LoadingContext } from '../../util/loading/provider'
import { WithChildren } from '../../util/models/childrenContext'

export const ContentContext = createContext<any | null>(null)

export const ContentProvider : FC<WithChildren> = ({ children }) => {

const [oneData/*, setOneData*/] = useState([])
    //{setShowLoad} = useContext(LoadingContext),
    //nameController = 'persona',
    // One = async () => {
    //     // setShowLoad(true)
    //     const response = await PostRoute(`${nameController}/get-perfil`)
    //     response !== 7001 && setOneData((response?.data !== null ) ? response.data[0] : [])
    //     setShowLoad(false)
    // }

    const value = {
        oneData
    }

    useEffect(
        () => {
            // const functionFetch=async()=>{
            //     await One()
            // }
            // functionFetch();
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []
    )

    return ( 
        <ContentContext.Provider value={value} >
            { children}
        </ContentContext.Provider>
    )

}


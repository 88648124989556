import Blowfish from 'javascript-blowfish'
import axios from 'axios';

// import { jwtDecode } from 'jwt-decode';

const RouteBase = process.env.REACT_APP_ROUTE_BASE

const GetStorage = () => {
    const bf = new Blowfish(process.env.REACT_APP_CONFIG_KEY)
    const storage = localStorage.getItem("user")
    const Decript = bf.decrypt(bf.base64Decode(storage))
    const data = JSON.parse(Decript.replace(/\0/g, ''))
    return data
}

export async function GetRoute(url) {

    const response = await fetch(`${RouteBase}/${url}`,
        {
            method: 'GET',
            mode: 'cors',
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${GetStorage().token}`,
            }
        }
    )
        .then(function (data) {
            //StatusCode(data);
            return data.json()
        }).catch(function (data) {
            //StatusCode(data);
            return [];
        });
    return await response;
}

export async function PostRoute(url, form) {
    try {
        // Enviamos el formulario con fetch por el método POST
        const response = await fetch(`${RouteBase}/${url}`, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${GetStorage().token}`,
            },
            body: JSON.stringify({ ...form, usuario: GetStorage()?.id })
        });

        // Verificamos si la respuesta fue exitosa (status code 200-299)
        if (!response.ok) {
            const errorData = await response.text(); // O 'response.json()' si el error viene en formato JSON
            throw new Error(`HTTP error! status: ${response.status}, data: ${errorData}`);
        }
        // Si todo está bien, procesamos la respuesta como JSON
        const data = await response.json();
        // Devolvemos un objeto que incluye tanto el status code como los datos
        return {
            ...data,
            status: response?.status,
            message: 'Proceso realizado con éxito.',
            code: 1
        };
    } catch (error) {
        // console.error("Error en PostRoute:", error.message);
        // Dependiendo de tus necesidades, puedes decidir cómo manejar el error aquí.
        // Por ejemplo, podrías devolver un obj`eto con información del error o simplemente devolver un valor por defecto.
        return { error: true, errorDatas: error.message, message: JSON.parse(error.message.split("data:")[1]).errorMessage, code: 0 };
    }
}

export async function PostRouteStore(url, form) {
    try {
        const response = await axios.post(`${RouteBase}/${url}`, {
            ...form,
            usuario: GetStorage()?.id
        }, {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${GetStorage().token}`,
            }
        });

        return {
            ...response.data,
            status: response.status,
            message: 'Proceso realizado exitosamente',
            code: 1
        };
    } catch (error) {
        let errorMessage = 'Error en el proceso';

        if (error.response && error.response.data) {
            errorMessage = error.response.data.message || errorMessage;
        }

        return {
            error: true,
            errorDatas: error.response ? error.response.data : error.message,
            message: errorMessage,
            code: 0
        };
    }
}

export async function PostRouteData(url, form) {
    try {
        const response = await fetch(`${RouteBase}/${url}`, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${GetStorage().token}`,
            },
            body: JSON.stringify({ ...form, usuario: GetStorage()?.id })
        });

        if (!response.ok) {
            // Intenta obtener el JSON para ver si hay un mensaje de error específico,
            // pero si falla, usa el status text como mensaje de error.
            try {
                const errorJson = await response.json();
                throw new Error(errorJson.message || response.statusText);
            } catch {
                throw new Error(response.statusText);
            }
        }

        // Si todo está bien, procesamos y devolvemos la respuesta como JSON directamente
        return await response.json();
    } catch (error) {
        // Devuelve un array vacío o cualquier otro valor por defecto que consideres apropiado para tu aplicación
        return [];
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    GetRoute,
    PostRoute,
    PostRouteData
}

import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { Download, ArrowLeft, Save } from 'react-feather';
import {Tooltip} from 'react-tooltip'

interface AttachmentModalProps {
  attachmentUrl: string; // URL del PDF o imagen a visualizar y descargar
  show: boolean; // Controla la visibilidad del modal
  onHide: () => void; // Función para ocultar el modal
}

const AttachmentModal: React.FC<AttachmentModalProps> = ({ attachmentUrl, show, onHide }) => {
  const [isImage, setIsImage] = useState<boolean>(/\.(jpeg|jpg|gif|png|svg)$/.test(attachmentUrl));

  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = attachmentUrl;
  
    // Extrae la extensión y el nombre del archivo de la URL del adjunto
    const fileExtension = attachmentUrl.split('.').pop();
    const fileName = attachmentUrl.split('/').pop() || `download.${fileExtension}`;
  
    link.setAttribute('download', fileName);
    document.body.appendChild(link); // Agrega el link al documento
    link.click(); // Simula un clic en el link para iniciar la descarga
    document.body.removeChild(link); // Elimina el link del documento
  };

  return (
    <Modal show={show} onHide={onHide} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>Visualizar Adjunto</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isImage ? (
          <img src={attachmentUrl} alt="Attachment" style={{ maxHeight: '500px', maxWidth: '100%', display: 'block', marginLeft: 'auto', marginRight: 'auto' }} className="img-fluid" />

        ) : (
          <iframe
            src={attachmentUrl}
            title="PDF"
            width="100%"
            height="500px"
            style={{ border: 'none' }}
          ></iframe>
        )}
      </Modal.Body>

      <Modal.Footer className='d-flex justify-content-center'>
            <Button
              data-tooltip-id='exit'
              data-tooltip-content='Salir'
              variant='secondary'
              size='sm'
              className='btn-icon me-5'
              onClick={onHide}
            >
              <ArrowLeft size={18} />
            </Button>
             
            <Tooltip id='exit' place='top' />
                <Button
                  id='save'
                  variant='primary'
                  size='sm'
                  className='btn-icon'
                  type='submit'
                  data-tooltip-id='download'
                  data-tooltip-content='Descargar'
                  onClick={handleDownload}
                >
                  <Download size={18} />
                </Button>
                <Tooltip id='download' place='top' />
          </Modal.Footer>
    </Modal>
  );
};

export default AttachmentModal;

import {FC} from 'react'
import {SidebarMenuItemWithSub} from '../../SidebarMenuItemWithSub'
import {SidebarMenuItem} from '../../SidebarMenuItem'
//import {SidebarModel} from '../../../../../../../app/util/models/sidebar'

interface CatalogosProps {
  path: string;
  userRoles?: string[];
}

const Catalogos: React.FC<CatalogosProps> = ({ path, userRoles }) => {

  const rolesConfig = JSON.parse(process.env.REACT_APP_ROLES || '{}');
  const isGerOrInf = userRoles?.includes(rolesConfig.ger) || userRoles?.includes(rolesConfig.inf);
  const isCons = userRoles?.includes(rolesConfig.cons);

  return (
    <SidebarMenuItemWithSub title='Catalogos' icon='/media/icons/duotune/abstract/abs029.svg'>
      {(isGerOrInf || isCons) && (
        <SidebarMenuItem
          to={`/${path}/ocupacion`}
          icon={<i className='bi bi-briefcase-fill' />}
          title='Ocupación'
        />
      )}
      {isGerOrInf && (
        <>
          <SidebarMenuItem
            to={`/${path}/estado-civil`}
            icon={<i className='bi bi-heart-fill' />}
            title='Estado Civil'
          />
          <SidebarMenuItem
            to={`/${path}/tipo-detalle`}
            icon={<i className='bi bi-grid-fill' />}
            title='Tipo Detalle'
          />
          <SidebarMenuItem
            to={`/${path}/tipo-familiar`}
            icon={<i className='bi bi-people-fill' />}
            title='Tipo Familiar'
          />
          <SidebarMenuItem
            to={`/${path}/tipo-noticia`}
            icon={<i className='bi bi-newspaper' />}
            title='Tipo de Noticia'
          />
        </>
      )}
    </SidebarMenuItemWithSub>
  )
}

export default Catalogos

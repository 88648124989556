/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import React, { FC } from 'react'
import { useLayout } from '../../../core'

type Props = {
  DefaultTitle: any
  pageModal?: any
}

const Toolbar3: FC<Props> = ({ DefaultTitle, pageModal }) => {
  const { classes } = useLayout()

  return (
    <div
      id='kt_toolbar_container'
      className={clsx(classes.toolbarContainer.join(' '), 'd-flex flex-stack justify-content-between fw-bolder text-uppercase')}
    >
      <h3>{DefaultTitle}</h3>
      <div className='d-flex align-items-center py-1'>
        {pageModal}
      </div>
    </div>
  )
}

export { Toolbar3 }
